<template>
  <v-dialog
    v-model="dialog"
    hide-overlay
    max-width="590px"
    persistent
  >
    <v-card
      width="590px"
      min-height="502px"
      class="custom-card px-3"
    >
      <v-card-title
        class="color-label text-h3 text-left font-weight-black pl-3"
      >
        Solicitar Conta de Acesso
      </v-card-title>
      <v-container>
        <v-form
          id="form_login"
          ref="form_login"
          v-model="valid"
          lazy-validation
          @submit.prevent="postLogin"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-label class="color-label">
                CPF/CNPJ *
              </v-label>
              <v-text-field
                v-model="formulario.cpf_cnpj"
                v-mask="[maskCpf,maskCnpj]"
                :rules="rulesCpfCnpj"
                placeholder="000.000.000-00"
                dense
                outlined
                hide-details
                validate-on-blur
                background-color="#E8EBF4"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-label class="color-label">
                Nome *
              </v-label>
              <v-text-field
                v-model="formulario.nome"
                placeholder="Nome Completo"
                :rules="[rules.required]"
                dense
                hide-details
                outlined
                validate-on-blur
                background-color="#E8EBF4"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              <v-label class="color-label">
                Data de Nascimento *
              </v-label>
              <v-text-field
                v-model="formulario.data_nascimento"
                v-mask="'##/##/####'"
                placeholder="00/00/0000"
                :rules="dateRules"
                hide-details
                dense
                validate-on-blur
                outlined
                background-color="#E8EBF4"
              />
            </v-col>
            <v-col
              cols="12"
              sm="8"
            >
              <v-label class="color-label">
                E-mail *
              </v-label>
              <v-text-field
                v-model="formulario.email"
                placeholder="loremipsum@email.com"
                dense
                validate-on-blur
                :rules="[rules.required, rules.email]"
                hide-details
                outlined
                background-color="#E8EBF4"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              <v-label class="color-label">
                Estado *
              </v-label>
              <v-autocomplete
                v-model="formulario.estado"
                placeholder="Selecione o Estado"
                :items="estados"
                :rules="[rules.required]"
                item-text="nome"
                item-value="id"
                return-object
                validate-on-blur
                dense
                hide-details
                background-color="#E8EBF4"
                outlined
                autocomplete="nope"
              />
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              <v-label class="color-label">
                Cidade *
              </v-label>
              <v-autocomplete
                v-model="formulario.cidade"
                :rules="[rules.required]"
                :items="cidades"
                autocomplete="nope"
                placeholder="Selecione a Cidade"
                item-text="nome"
                validate-on-blur
                hide-details
                background-color="#E8EBF4"
                dense
                outlined
                item-value="nome"
                :disabled="
                  !formulario.estado ||
                    $asyncComputed.estados.updating ||
                    $asyncComputed.cidades.updating
                "
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-label class="color-label">
                Celular/WhatsApp *
              </v-label>
              <v-text-field
                v-model="formulario.celular"
                v-mask="'(##) #####-####'"
                placeholder="(00) 00000-0000"
                :rules="validarCelular"
                hide-details
                validate-on-blur
                dense
                outlined
                background-color="#E8EBF4"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-label class="color-label">
                Telefone
              </v-label>
              <v-text-field
                v-model="formulario.telefone"
                v-mask="'(##) ####-####'"
                placeholder="(00) 0000-0000"
                hide-details
                dense
                validate-on-blur
                outlined
                background-color="#E8EBF4"
              />
            </v-col>
          </v-row>
          <v-row
            no-gutters
            dense
            class="mt-4"
          >
            <v-col cols="auto">
              <span class="color-label text-h3 font-weight-black">Senhas</span>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col
              cols="12"
              sm="6"
            >
              <v-label class="color-label">
                Senha para acesso: *
              </v-label>
              <v-text-field
                v-model="formulario.password"
                placeholder="Digite a senha"
                background-color="#E8EBF4"
                :rules="passwordRules"
                autocomplete="new-password"
                hide-details
                validate-on-blur
                dense
                outlined
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-label class="color-label">
                Senha para acesso: *
              </v-label>
              <v-text-field
                v-model="confirm_password"
                background-color="#E8EBF4"
                :rules="confirmPasswordRules"
                outlined
                placeholder="Confirme a senha"
                autocomplete="new-password"
                hide-details
                dense
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                v-model="formulario.termo_adesao"
                label="Termo de Adesão/Uso"
                color="#2DADED"
                class="bold-label"
                dark
                hide-details
                :rules="[rules.required]"
                dense
                @click="openTermoAdesao"
              />
            </v-col>
            <v-col>
              <v-checkbox
                v-model="formulario.politica_privacidade"
                label="Termo de Privacidade"
                dark
                :rules="[rules.required]"
                color="#2DADED"
                hide-details
                dense
                class="bold-label"
                @click="openTermoPrivacidade"
              />
            </v-col>
          </v-row>
          <v-dialog
            v-model="dialogTermoAdesao"
            persistent
            max-width="650px"
          >
            <v-card>
              <v-container>
                <iframe
                  src="/termos/politica_privacidade.html"
                  width="100%"
                  height="480"
                  style="border: none;"
                />
                <v-row>
                  <v-spacer />
                  <v-col cols="auto">
                    <v-btn
                      color="error"
                      dark
                      @click="negarTermoAdesao"
                    >
                      Cancelar
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      color="#2DADED"
                      dark
                      @click="aceitarTermoAdesao"
                    >
                      Concordar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogPrivacidade"
            persistent
            max-width="650px"
          >
            <v-card>
              <v-container>
                <iframe
                  src="/termos/politica_privacidade.html"
                  width="100%"
                  height="480"
                  style="border: none;"
                />
                <v-row>
                  <v-spacer />
                  <v-col cols="auto">
                    <v-btn
                      color="error"
                      dark
                      @click="negarTermoPrivacidade"
                    >
                      Cancelar
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      color="#2DADED"
                      dark
                      @click="aceitarTermoPrivacidade"
                    >
                      Concordar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
          <v-row justify="end">
            <v-col cols="auto">
              <v-btn
                color="error"
                style="border-radius: 8px;"
                class="text-none"
                :small="$vuetify.breakpoint.xs"
                dark
                @click="close"
              >
                <v-icon left>
                  mdi-minus-circle
                </v-icon>
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="#2DADED"
                style="border-radius: 8px;"
                class="text-none"
                :small="$vuetify.breakpoint.xs"
                dark
                @click="postRegister"
              >
                <v-icon left>
                  mdi-check-bold
                </v-icon>
                Solicitar Conta
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
  import { validarCNPJ, validarCPF } from '../../utils/validacoes'
  import Swal from 'sweetalert2'
  import rules from '../../utils/formRules'
  export default {
    name: 'LoginRegister',
    props: {
      dialog: {
        type: Boolean,
        default: false,
      },
      close: {
        type: Function,
        default: () => {},
      },
    },
    data () {
      return {
        formulario: {
          nome: '',
          data_nascimento: '',
          cpf_cnpj: '',
          estado: '',
          cidade: '',
          email: '',
          celular: '',
          telefone: null,
          status: 0,
          password: '',
          termo_adesao: false,
          politica_privacidade: false,
        },
        dialogTermoAdesao: false,
        dialogPrivacidade: false,
        teste: false,
        confirm_password: null,
        valid: null,
        show1: false,
        show2: false,
        rules,
        passwordRules: [
          v => !!v || 'Senha é obrigatória',
          v => (v && v.length >= 8) || 'A senha deve ter pelo menos 8 caracteres',
        ],
        confirmPasswordRules: [
          v => !!v || 'Confirmação de senha é obrigatória',
          v => v === this.formulario.password || 'As senhas não coincidem',
        ],
        dateRules: [
          v => !!v || 'Data de nascimento é obrigatória',
          v => !v || /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/.test(v) || 'Formato de data deve ser DD/MM/AAAA',
          v => {
            if (!v) return true
            const [day, month, year] = v.split('/')
            const date = new Date(year, month - 1, day)
            return (date && date.getMonth() + 1 === parseInt(month) && date.getDate() === parseInt(day)) || 'Data inválida'
          },
          v => {
            if (!v) return true
            const [day, month, year] = v.split('/')
            const date = new Date(year, month - 1, day)
            const now = new Date()
            const sixteenYearsAgo = new Date(now.getFullYear() - 16, now.getMonth(), now.getDate())
            const oneHundredFiftyYearsAgo = new Date(now.getFullYear() - 150, now.getMonth(), now.getDate())

            return (date < sixteenYearsAgo && date > oneHundredFiftyYearsAgo) || 'Data inválida'
          },
        ],
        maskCpf: '###.###.###-##',
        maskCnpj: '##.###.###/####-##',
        message: '',
      }
    },
    asyncComputed: {
      estados: {
        async get () {
          const estadosAutorizados = ['SC', 'PR', 'MG']
          const dataEstados = await this.api.estados(1)
          return dataEstados.filter(estado => {
            return estadosAutorizados.includes(estado.sigla)
          })
        },
        default: [],
      },
      cidades: {
        get () {
          return this.formulario.estado
            ? this.api.cidades(1, this.formulario.estado.id).then(cidades => cidades)
            : []
        },
        default: [],
      },
    },
    computed: {
      rulesCpfCnpj () {
        return [
          v => {
            if (!v) return 'Campo obrigatório'
            const onlyNumbers = v.replace(/\D/g, '')
            if (onlyNumbers.length === 11) {
              return validarCPF(onlyNumbers) || 'CPF inválido'
            } else if (onlyNumbers.length === 14) {
              return validarCNPJ(onlyNumbers) || 'CNPJ inválido'
            } else {
              return 'CPF ou CNPJ incompleto'
            }
          },
        ]
      },
      validarCelular () {
        return [
          v => {
            if (!v) return 'Campo obrigatório'
            const cleanNumero = v.replace(/\D/g, '')
            const regex = /^[1-9]{2}9[1-9]\d{3}\d{4}$/
            if (!regex.test(cleanNumero)) return 'Celular inválido'
            return true
          },
        ]
      },
    },
    methods: {
      formatarData (data) {
        if (!data) return ''
        const [day, month, year] = data.split('/')
        return `${year}-${month}-${day}`
      },
      postRegister () {
        if (this.$refs.form_login.validate()) {
          this.formulario.estado = this.formulario.estado.nome
          this.formulario.data_nascimento = this.formatarData(this.formulario.data_nascimento)
          Swal.fire({
            title: 'Carregando...',
            html: 'Aguarde enquanto processamos seu cadastro.',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading()
            },
          })
          return this.api.cadastrarEntidade(this.formulario, 'register').then(response => {
            Swal.fire({
              icon: 'success',
              title: 'Cadastro realizado com sucesso',
              text: 'Aguarde a aprovação do administrador',
            })
            this.close()
            this.$refs.form_login.reset()
          }).catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'Erro ao cadastrar',
              text: err.message,
            })
          })
        }
        window.Toast.fire({
          icon: 'error',
          title: 'Preencha todos os campos corretamente',
        })
      },
      openTermoAdesao () {
        this.dialogTermoAdesao = true
      },
      aceitarTermoAdesao () {
        this.formulario.termo_adesao = true
        this.dialogTermoAdesao = false
      },
      negarTermoAdesao () {
        this.formulario.termo_adesao = false
        this.dialogTermoAdesao = false
      },
      openTermoPrivacidade () {
        this.dialogPrivacidade = true
      },
      aceitarTermoPrivacidade () {
        this.formulario.politica_privacidade = true
        this.dialogPrivacidade = false
      },
      negarTermoPrivacidade () {
        this.formulario.politica_privacidade = false
        this.dialogPrivacidade = false
      },
    },
  }
</script>
<style scoped>

.custom-card {
  background: radial-gradient(
    151.92% 127.02% at 15.32% 21.04%,
    rgba(45, 202, 237, 0.20) 0%,
    rgba(84, 154, 204, 0.02) 77.08%,
    rgba(121, 143, 223, 0.20) 100%);
  backdrop-filter: blur(47px);
}

.v-input {
    border-radius: 8px;
}

.v-sheet.v-card {
  border-radius: 8px;
}

.color-label {
  color: #E8EBF4;
}

.bold-label {
  font-weight: bold;
}
</style>
