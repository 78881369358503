// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/loginimage1.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".v-card[data-v-7d1c9e50]{border-radius:20px}.back[data-v-7d1c9e50]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position:top}.layout-logos-login[data-v-7d1c9e50]{-webkit-box-align:center!important;-ms-flex-align:center!important;align-items:center!important}.gradient-line-right[data-v-7d1c9e50]{background:-webkit-gradient(linear,right top,left top,from(#b6b6b6),color-stop(94.39%,hsla(0,0%,85.1%,0)));background:linear-gradient(270deg,#b6b6b6,hsla(0,0%,85.1%,0) 94.39%)}.gradient-line-left[data-v-7d1c9e50],.gradient-line-right[data-v-7d1c9e50]{height:2px;width:100px;display:inline-block}.gradient-line-left[data-v-7d1c9e50]{background:-webkit-gradient(linear,right top,left top,from(hsla(0,0%,85.1%,0)),color-stop(94.39%,#b6b6b6));background:linear-gradient(270deg,hsla(0,0%,85.1%,0),#b6b6b6 94.39%)}.v-input[data-v-7d1c9e50]{border-radius:8px}", ""]);
// Exports
module.exports = exports;
