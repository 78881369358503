<template>
  <v-app>
    <div
      class="back"
      style="position: fixed; height: 100vh; width: 100vw;"
    >
      <div
        style="position: absolute; top: 0; right: 0; padding: 15px; text-align: right;"
      >
        <span style="font-size: 12px; color: white; "> {{ version }}</span>
      </div>
      <v-container class="fill-height">
        <v-container
          :class="dialog ? 'mb-16' : ($vuetify.breakpoint.xs ? 'mb-auto' : 'mb-auto mt-10')"
          :style="($vuetify.breakpoint.xs ? 'padding: 4px;' : 'width: 400px;')"
        >
          <v-container>
            <v-row
              class="layout-logos-login fill-height px-4 height"
              align="center"
              justify="center"
            >
              <v-img
                src="@/assets/logo_branca.png"
                height="200px"
                contain
              />
            </v-row>
          </v-container>
          <div
            v-if="!loader"
          >
            <v-form
              id="form_login"
              ref="form_login"
              v-model="valid"
              @submit.prevent="postLogin"
            >
              <div>
                <div
                  style="color: #E8EBF4; margin-bottom: 5px; margin-left: 5px;"
                  :class="!$vuetify.breakpoint.xs ? 'mt-8' : 'mt-0'"
                >
                  Usuário
                </div>
                <v-text-field
                  v-model="userEntrada"
                  :rules="loginRules"
                  background-color="#E8EBF4"
                  hide-details
                  solo
                  label="Email ou CPF/CNPJ"
                  name="userEntrada"
                  prepend-inner-icon="mdi-account"
                  validate-on-blur
                  style="margin-bottom: 18px;"
                />
                <div style="color: #E8EBF4; margin-bottom: 5px; margin-left: 5px">
                  Senha
                </div>
                <v-text-field
                  v-model="password"
                  background-color="#E8EBF4"
                  hide-details
                  solo
                  prepend-inner-icon="mdi-key"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required]"
                  :type="show2 ? 'text' : 'password'"
                  name="password"
                  label="Senha"
                  @click:append="show2 = !show2"
                />
                <div
                  style="text-align: end; margin-bottom: 28px;"
                >
                  <v-btn
                    x-small
                    text
                    class="text-none"
                    @click="passwordForgot"
                  >
                    <span
                      style="font-size: 12px; color: #E8EBF4; margin-top: 13px;"
                    >Esqueci minha senha</span>
                  </v-btn>
                </div>
                <v-row
                  justify="center"
                >
                  <v-btn
                    type="submit"
                    width="250"
                    class="text-none"
                    style="background-color: #2DADED; color: white; margin-top: 26px; border-radius: 8px;"
                  >
                    <span style="font-weight: 600">Acessar</span>
                  </v-btn>
                </v-row>
                <v-row
                  justify="center"
                  :class="$vuetify.breakpoint.lgAndDown ? ' mt-4 mb-n2' : 'mt-8 mb-5'"
                >
                  <div class="gradient-line-right my-auto mr-1" />
                  <span style="font-weight: 600; color: rgba(182, 182, 182, 1);">ou</span>
                  <div class="gradient-line-left my-auto ml-1" />
                </v-row>
                <v-row
                  justify="center"
                >
                  <v-btn
                    class="text-none elevation-0 outline"
                    color="transparent"
                    style="color: white; outline: 1px solid #fff; border-radius: 8px;"
                    @click="dialog = true"
                  >
                    <span style="font-weight: 600">Solicitar conta de acesso</span>
                  </v-btn>
                  <login-register
                    :dialog="dialog"
                    :close="closeDialog"
                  />
                </v-row>
              </div>
            </v-form>
          </div>
          <v-container v-else>
            <v-row
              style="height: 229px"
              justify="center"
              align="center"
            >
              <v-progress-circular
                :size="100"
                :width="8"
                color="primary"
                :indeterminate="true"
              />
            </v-row>
          </v-container>
        </v-container>

        <v-snackbar
          v-model="snackbar"
          top
          right
          :timeout="4000"
          color="error"
          transition="slide-x-reverse-transition"
        >
          <v-row
            justify="space-between"
          >
            <div class="d-flex align-center">
              <v-icon class="mx-2">
                mdi-alert-circle-outline
              </v-icon>
              <span style="font-size: 14px; font-weight: 500">{{ message }}</span>
            </div>
            <v-btn
              small
              icon
              dark
              text
              @click="snackbar = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-snackbar>
      </v-container>
    </div>
    <v-banner
      style="position: absolute; bottom: 0;"
      two-line
      class="ma-4"
      elevation="11"
      color="white"
      shaped
      width="98%"
      transition="slide-y-transition"
    >
      O Clube do Produtor utiliza cookies para melhorar a experiência do usuário. Por favor veja nossa <a
        href="https://www.iubenda.com/privacy-policy/76325752/cookie-policy"
        target="_blank"
        color="primary"
      > <span
        style="color: #000509; font-weight: 700;"
      >política de privacidade</span></a> para maiores informações. Ao clicar em "Aceitar Cookies" ou continuar a utilizar o site, você concorda com o uso de Cookies.

      <template v-slot:actions="{ dismiss }">
        <v-btn
          class="text-none"
          color="#163b92"
          rounded
          variant="outlined"
          dark
        >
          Recusar Cookies
        </v-btn>

        <v-btn
          class="text-none ms-4 text-white"
          color="#163b92"
          rounded
          dark
          @click="dismiss"
        >
          Aceitar Cookies
        </v-btn>
      </template>
    </v-banner>
  </v-app>
</template>
<script>
  import { login } from '@/services/AuthService'
  import { validarCNPJ, validarCPF } from '../../utils/validacoes'
  import email from 'vuelidate/lib/validators/email'
  import rules from '../../utils/formRules'
  import packageJson from '../../../package.json'
  import LoginRegister from './LoginRegister.vue'

  export default {
    components: { LoginRegister },
    props: ['cadastro'],
    data () {
      return {
        dialog: false,
        advertising: true,
        performance: true,
        userEntrada: null, //
        password: null,
        email: null,
        formEnv: null,
        cpf: null,
        cnpj: null,
        user: null,
        snackbar: false,
        message: '',
        loader: false,
        valid: null,
        show1: false,
        show2: false,
        rules,
        loginRules: [
          (v) => {
            return (
              validarCPF(this.userEntrada) || validarCNPJ(this.userEntrada) || email(this.userEntrada)
            ) || 'Favor inserir um email, CPF ou CNPJ válido'
          },
          rules.required,
        ],
        version: this.getVersion(),
      }
    },
    created () {
      if (this.cadastro && this.cadastro === 'cadastro') {
        this.dialog = true
      }
    },
    methods: {
      postLogin () {
        if (this.$refs.form_login.validate()) {
          this.loader = true
          this.api.login(this.userEntrada, this.password).then(response => {
            login(response.user, response.access_token)
            return this.api.listEntidade('permission', { params: { list: true } })
          }).then(response => {
            this.$store.commit('SET_PERMISSIONS', response)
          }).catch(_err => {
            this.message = _err.message
            this.snackbar = true
            this.loader = false
          })
        }
      },
      passwordForgot () {
        this.Swal.fire({
          title: 'Digite o seu email',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off',
          },
          inputValue: this.email,
          showCancelButton: true,
          confirmButtonText: 'Enviar',
          showLoaderOnConfirm: true,
          preConfirm: email => {
            this.email = email
            return this.api.forgotPassword(email).then(
              data =>
                this.Swal.fire(
                  'E-mail enviado',
                  'E-mail com link para alteração de senha enviado!',
                  'success',
                ),
              error => {
                this.Swal.fire({
                  icon: 'error',
                  title: 'Insira um e-mail válido!',
                  text: error.message,
                })
              },
            )
          },
        })
      },
      getVersion () {
        const versionApi = localStorage.getItem('version_api')
        return 'versão: ' + (
          versionApi
            ? (versionApi + (process.env.NODE_ENV !== 'production' ? '(dev)' : '') + (packageJson.version !== versionApi
              ? '*' : ''))
            : packageJson.version + (process.env.NODE_ENV !== 'production' ? '(dev)' : '') + '*'
        )
      },
      closeDialog () {
        this.dialog = false
      },
    },
  }
</script>
<style lang="css" scoped>

.v-card {
  border-radius: 20px;
}

.back {
  background-image: url('../../assets/loginimage1.png');
  background-size: cover;
  background-position: top center;
}

.layout-logos-login {
    align-items: center!important;
}

.gradient-line-right {
  height: 2px;
  width: 100px;
  background: linear-gradient(270deg,rgba(182, 182, 182, 1) 0%, rgba(217, 217, 217, 0) 94.39%);
  display: inline-block;
}

.gradient-line-left {
  height: 2px;
  width: 100px;
  background: linear-gradient(270deg, rgba(217, 217, 217, 0) 0%,rgba(182, 182, 182, 1) 94.39%);
  display: inline-block;
}

.v-input {
    border-radius: 8px;
}
</style>
